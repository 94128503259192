import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/member/Dashboard.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "ยินดีต้อนรับ",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/depositlog",
        name: "deposit-logs",
        component: () => import("@/views/member/deposit/Logs.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "ประวัติการฝาก",
        },
      },
      {
        path: "/withdrawlog",
        name: "withdraw-logs",
        component: () => import("@/views/member/withdraw/Logs.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "ประวัติการถอน",
        },
      },
      {
        path: "/playLog",
        name: "play-logs",
        component: () => import("@/views/member/game/Logs.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "ประวัติการเล่น",
        },
      },
      {
        path: "/withdraw",
        name: "withdraw",
        component: () => import("@/views/member/withdraw/Index.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "ถอนเงิน",
          breadcrumbs: ["Withdraw"],
        },
      },
      {
        path: "/deposit",
        name: "deposit-hd",
        //component: () => import("@/views/member/deposit/Index.vue"),
        meta: {
          middleware: "auth",
        },
        children: [
          {
            path: "/deposit",
            name: "deposit",
            component: () => import("@/views/member/deposit/Index.vue"),
            meta: {
              middleware: "auth",
              pageTitle: "ฝากเงิน",
              breadcrumbs: ["Deposit"],
            },
          },
          {
            path: "/deposit/inform",
            name: "deposit-inform",
            component: () => import("@/views/member/deposit/Inform.vue"),
            meta: {
              middleware: "auth",
              pageTitle: "แจ้งฝากเงิน",
              breadcrumbs: ["deposit-inform"],
            },
          },
          {
            path: "/deposit/slipInform",
            name: "deposit-slip-inform",
            component: () => import("@/views/member/deposit/SlipInform.vue"),
            meta: {
              middleware: "auth",
              pageTitle: "แจ้งฝากเงิน",
              breadcrumbs: ["deposit-inform"],
            },
          },
          {
            path: "/deposit/qrscan/:id",
            name: "deposit-qrscan",
            component: () => import("@/views/member/deposit/QrScan.vue"),
            meta: {
              middleware: "auth",
              pageTitle: "แสกนจ่าย",
              breadcrumbs: ["deposit-qrscan"],
            },
          },
        ],
      },
      {
        path: "/team",
        name: "team",
        component: () => import("@/views/member/profile/Team.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "ชวนเพื่อนเล่น",
          breadcrumbs: ["team"],
        },
      },
      {
        path: "/wheelgame",
        name: "wheelgame",
        component: () => import("@/views/member/wheelgame/Index.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "เกมส์วงล้อ",
          breadcrumbs: ["wheelgame"],
        },
      },
      {
        path: "/play-wheelgame",
        name: "play-wheelgame",
        component: () => import("@/views/member/wheelgame/Play.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "เกมส์วงล้อ",
          breadcrumbs: ["wheelgame"],
        },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/member/profile/Index.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "โปรไฟล์",
          breadcrumbs: ["profile"],
        },
      },
      {
        path: "/promotion",
        name: "promotion",
        component: () => import("@/views/member/promotion/Index.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "โปรโมชั่น",
          breadcrumbs: ["promotion"],
        },
      },
      {
        path: "/salary",
        name: "salary",
        component: () => import("@/views/member/salary/Index.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "เงินเดือน",
          breadcrumbs: ["salary"],
        },
      },
      {
        path: "/continuous",
        name: "continuous",
        component: () => import("@/views/member/continuous/Index.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "ฝากต่อเนื่อง",
          breadcrumbs: ["continuous"],
        },
      },
      {
        path: "/luckyplay",
        name: "luckyplay",
        component: () => import("@/views/member/luckyplay/Index.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "ทายเลข 2 ตัว",
          breadcrumbs: ["luckyplay"],
        },
      },
      {
        path: "/freewheel",
        name: "freewheel",
        component: () => import("@/views/member/wheelgame/Free.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "วงล้อฟรี",
          breadcrumbs: ["freewheel"],
        },
      },
      {
        path: "/reward",
        name: "reward-hd",
        //component: () => import("@/views/member/deposit/Index.vue"),
        meta: {
          middleware: "auth",
        },
        children: [
          {
            path: "/reward",
            name: "reward",
            component: () => import("@/views/member/reward/Index.vue"),
            meta: {
              middleware: "auth",
              pageTitle: "แลกของรางวัล",
              breadcrumbs: ["Reward"],
            },
          },
          {
            path: "/reward/redeem/:id",
            name: "reward-redeem",
            component: () => import("@/views/member/reward/Redeem.vue"),
            meta: {
              middleware: "auth",
              pageTitle: "ยืนยันแลกของรางวัล",
              breadcrumbs: ["reward-redeem"],
            },
          },
          {
            path: "/reward/redeem-diamond/:id",
            name: "reward-redeem-diamond",
            component: () => import("@/views/member/reward/RedeemDiamond.vue"),
            meta: {
              middleware: "auth",
              pageTitle: "ยืนยันแลกของรางวัล",
              breadcrumbs: ["reward-redeem-diamond"],
            },
          },
        ],
      },

      /*       {
        path: "/bonus",
        name: "bonus",
        component: () => import("@/views/member/bonus/Index.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "โบนัส",
          breadcrumbs: ["bonus"],
        },
      }, */

      {
        path: "/commission",
        name: "commission",
        component: () => import("@/views/member/commission/Index.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "คอมมิชชั่น",
          breadcrumbs: ["commission"],
        },
      },
      {
        path: "/loss",
        name: "loss-bonus",
        component: () => import("@/views/member/loss/Index.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "เล่นเสียรับคืน",
          breadcrumbs: ["commission"],
        },
      },
      {
        path: "/million",
        name: "million",
        component: () => import("@/views/member/million/Index.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "แจกแสนแจกล้าน",
          breadcrumbs: ["million"],
        },
      },
      {
        path: "/million-log",
        name: "million-log",
        component: () => import("@/views/member/million/Logs.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "ประวัติการได้รับ",
          breadcrumbs: ["Million"],
        },
      },
      {
        path: "/million-detail/:id",
        name: "million-detail",
        component: () => import("@/views/member/million/Detail.vue"),
        meta: {
          middleware: "auth",
          pageTitle: "ประกาศรายชื่อผู้โชคดี",
          breadcrumbs: ["Million"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    //component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/login/:id?",
        name: "sign-in",
        component: () => import("@/views/pub/SignIn.vue"),
        meta: {
          pageTitle: "เข้าสู่ระบบ",
          breadcrumbs: ["sign-in"],
        },
      },
      {
        path: "/login/:id?",
        name: "sign-in-sp",
        component: () => import("@/views/pub/SignIn.vue"),
        meta: {
          pageTitle: "เข้าสู่ระบบ",
          breadcrumbs: ["sign-in"],
        },
      },

      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/pub/PasswordReset.vue"),
        meta: {
          pageTitle: "ลืมรหัสผ่าน",
          breadcrumbs: ["password-reset"],
        },
      },
      {
        path: "/register/:id?",
        name: "sign-up",
        component: () => import("@/views/pub/SignUp.vue"),
        meta: {
          pageTitle: "สมัครสมาชิก",
          breadcrumbs: ["sign-up"],
        },
      },
      {
        path: "/ref/:id",
        name: "sponsor-link",
        //component: () => import("@/components/page-layouts/Auth.vue"),
        component: () => import("@/views/pub/SignUp.vue"),
        meta: {
          pageTitle: "สมัครสมาชิก",
          breadcrumbs: ["sign-up"],
        },
      },
      {
        path: "/sr/:id",
        name: "seo-link",
        //component: () => import("@/components/page-layouts/Auth.vue"),
        component: () => import("@/views/pub/SignUp.vue"),
        meta: {
          pageTitle: "สมัครสมาชิก",
          breadcrumbs: ["sign-up"],
        },
      },
    ],
  },
  {
    path: "/singleAuth/:id",
    name: "single-auth",
    component: () => import("@/components/page-layouts/Auth.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/layout/Layout.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/pub/Error404.vue"),
    meta: {
      pageTitle: "Error 404",
    },
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/pub/Error500.vue"),
    meta: {
      pageTitle: "Error 500",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(), //createWebHashHistory  createWebHistory
  routes,
});
const signOut = (route) => {
  store.dispatch(Actions.LOGOUT).then(() => redirectToLogin(route));
};
const setRef = (id: string) => {
  //store.commit(Mutations.SET_BX_SPONSOR_MUTATION, {"ref_id":id}).then(() => router.push({ name: "sign-up" }));
  store.dispatch(Actions.LOGOUT).then(() => {
    store.commit(Mutations.SET_BX_SPONSOR_MUTATION, { ref_id: id });
    //router.push({ name: "sign-in" });
  });
};
const setSeo = (id: string) => {
  //store.commit(Mutations.SET_BX_SPONSOR_MUTATION, {"ref_id":id}).then(() => router.push({ name: "sign-up" }));
  store.dispatch(Actions.LOGOUT).then(() => {
    store.commit(Mutations.SET_BX_SEO_MUTATION, { ref_id: id });
    //router.push({ name: "sign-in" });
  });
};
const singleAuth = (id: string) => {
  store
    .dispatch(Actions.SINGLE_AUTH, {
      token: id,
    })
    .then(() => {
      if (store.getters.isUserAuthenticated) {
        router.push({ name: "deposit-inform" });
        /*         return {
          name: "deposit-inform",
          replace: true,
        }; */
      } else {
        router.push({ name: "sign-in" });
      }
    });
};
const redirectToLogin = (route) => {
  //console.log("redirectToLogin");
  const LOGIN = "sign-in";
  if (route.name != LOGIN) {
    //console.log("route.name != LOGIN");
    return {
      name: LOGIN,
      replace: true,
      //query: { redirectFrom: route.fullPath },
    };
  }
};
/* const redirectToHome = (route) => {
  const DEFAULT = "dashboard";
  return { name: DEFAULT, replace: true };
}; */
router.beforeEach((to, from) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // before page access check if page requires authentication
  if (to.meta.middleware === "auth") {
    // verify auth token before each page change
    store
      .dispatch(Actions.VERIFY_AUTH, {
        api_token: JwtService.getToken(),
        refresh_token: JwtService.getRefreshToken(),
      })
      .then(() => {
        if (store.getters.isUserAuthenticated) {
          //next();
          return true;
        } else {
          //next({ name: "sign-in" });
          redirectToLogin(to);
        }
      });
  } else {
    if (to.name === "logout") {
      return signOut(to);
    } else if (to.name === "sponsor-link") {
      setRef(to.params.id as string);
      return true; //redirectToLogin(to);
    } else if (to.name === "seo-link") {
      setSeo(to.params.id as string);
      return true; //redirectToLogin(to);
    } else if (to.name === "single-auth") {
      return singleAuth(to.params.id as string);
    } else {
      //next();
      return true;
    }
  }
  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
