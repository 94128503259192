enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  ASYNC_VERIFY_AUTH = "verifyAuthAsync",
  SINGLE_AUTH = "singleAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",

  GET_APPINFO = "getAppInfoAction",
  CHECK_USERCREDIT = "checkBxCreditAction",
  CHECK_TURNOVER_ACTION = "checkBxTurnOverAction",
  CHECK_USERINFO_ACTION = "checkBxUserInfoAction",

  SET_LAST_QR_ID_ACTION = "setLastQrIdAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",

  SET_BX_APPINFO_MUTATION = "setBxAppInfoMutation",
  SET_BX_USERCREDIT_MUTATION = "setBxUserCreditMutation",

  SET_BX_SPONSOR_MUTATION = "setBxSponsorMutation",
  SET_BX_SEO_MUTATION = "setBxSeoMutation",

  SET_BX_TURNOVER_MUTATION = "setBxTurnOverMutation",

  SET_LAST_QR_ID = "setLastQrId",
  RESET_LAST_QR_ID = "resetLastQrId",
}

export { Actions, Mutations };
